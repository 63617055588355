import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

const Seo = ({ description, lang, meta, keywords, title, socialImage, uri = "" }) => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const { site, wp } = data

        const siteUrl = "https://www.choosemketech.org"

        const metaDescription = description || wp.generalSettings.description || site.siteMetadata.description

        const titleText = title ? `${title} | ${wp.generalSettings.description || site.siteMetadata.title}` : wp.generalSettings.description || site.siteMetadata.title

        const image = socialImage ? socialImage.node.sourceUrl : ""
        const imageAlt = socialImage ? socialImage.node.imageAlt : ""

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={titleText}
            titleTemplate={`%s`}
            link={[
              {
                rel: "canonical",
                href: siteUrl + uri,
              },
            ]}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: "og:url",
                content: siteUrl + uri,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                property: "og:image",
                content: image,
              },
              {
                property: "og:image:width",
                content: "1920",
              },
              {
                property: "og:image:height",
                content: "1080",
              },
              {
                name: "twitter:image",
                content: image,
              },
              {
                name: "twitter:image:width",
                content: "1920",
              },
              {
                name: "twitter:image:height",
                content: "1080",
              },
              {
                name: "twitter:image:alt",
                content: imageAlt,
              },
            ].concat(
              keywords.length > 0
                ? {
                    name: `keywords`,
                    content: keywords.join(`, `),
                  }
                : []
            )}
          />
        )
      }}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  image: PropTypes.object,
}

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
    wp {
      generalSettings {
        title
        description
      }
    }
  }
`

export default Seo
